/* --- .provide-services --- */
.provide-services {
  position: relative;
  margin-bottom: 30px;
  &_img {
    margin-bottom: 35px;
  }
  &_title {
    font-size: 36px;
    line-height: 1.3;
  }
  &_description {
    color: #777;
    line-height: 1.8;
  }
  &:before {
    content: '';
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    padding: 0 15px;
    background: #fff;
    background: -webkit-linear-gradient(#FFF, #F8F8F8);
    background: -o-linear-gradient(#FFF, #F8F8F8);
    background: -moz-linear-gradient(#FFF, #F8F8F8);
    background: linear-gradient(#FFF, #F8F8F8);
  }
}

/* --- services --- */
.services {
  .page-services-grid-01 &,
  .page-services-grid-03 & {
    margin-bottom: 50px;
  }
  .page-services-grid-02 & {
    margin-bottom: 20px;
  }
  .page-services-list & {
    margin-bottom: 70px;
  }
  .page-home03 & {
    padding: 70px 0px 50px;
  }
}

.service-heading {
  background: #111;
  padding: 70px 0px;
  margin-bottom: 30px;
  p {
    color: rgba(225,225,225,0.5)
  }
}

.service-item {
  position: relative;
  margin-bottom: 60px;
  &:hover {
    .service-item_img img {
      @include scale(1.1);
    }
  }
}

.service-item_img {
  overflow: hidden;
  img {
    @extend %transit;
  }
}

.service-item_icon {
  position: relative;
  font-size: 32px;
  width: 50px;
  height: 77px;
  line-height: 77px;
  text-align: center;
  display: inline-block;
  background: #111;
  color: #fff;
  z-index: 999;
  @extend %transit;
  &:before, &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: rgba(0,0,0,0);
    @extend %transit;
  }
  &:before {
    border-width: 38.5px 20px 38.5px 0px;
    border-right-color: #111;
    right: 100%;
  }
  &:after {
    border-width: 38.5px 0px 38.5px 20px;
    border-left-color: #111;
    left: 50px;
  }
}

.service-item_link {
  font-family: Montserrat;
  font-size: 16px;
  color: #111;
  font-weight: 600;
  text-transform: capitalize;
  display: block;
  &:hover {
    color: #111;
  }
}

/* --- Grid 01 --- */
.services-style-01 {
  .service-item:hover {
    .service-item_icon {
      color: #111;
      background: #eee;
      &:before {
        border-right-color: #eee;
      }
      &:after {
        border-left-color: #eee;
      }
    }
  }
  .service-item_icon {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .service-item_link {
    position: absolute;
    bottom: -38px;
    right: 0px;
    width: 80%;
    background: $color-1;
    padding: 16px 20px;
    text-align: right;
  }
}

/* --- Grid 02 --- */
.services-style-02 {
  .service-item_icon {
    position: absolute;
    top: -22px;
    left: 0;
    color: #111;
    background: $color-1;
    &:before {
      border-right-color: $color-1;
    }
    &:after {
      border-left-color: $color-1;
    }
  }
  .service-item_link {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: $color-1;
    padding: 16px 20px;
    text-align: right;
  }
}

/* --- Grid 03 --- */
.services-style-03 {
  .service-item_icon {
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .service-item_link {
    text-transform: uppercase;
    font-size: 18px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  .service-item_description {
    color: #777;
  }
  .service-item:hover {
    .service-item_icon {
      color: #111;
      background: $color-1;
      &:before {
        border-right-color: $color-1;
      }
      &:after {
        border-left-color: $color-1;
      }
    }
  }
}

/* --- List --- */
.service-list_item {
  border: 2px solid #eee;
  border-bottom: none;
  padding: 30px 15px;
  &:last-child {
    border-bottom: 2px solid #eee;
  }
}

.service-list_item-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0;
    background-color: #1d2736;
    opacity: 0.8;
    z-index: 1;
  }

  &-content {
    @include translate-y-center;
    width: 100%;
    text-align: center;
    z-index: 2;
  }
  .service-item_icon {
    color: #111;
    background: $color-1;
    margin-bottom: 20px;
    &:before {
      border-right-color: $color-1;
    }
    &:after {
      border-left-color: $color-1;
    }
  }
  .service-item_link {
    font-size: 24px;
    color: #fff;
  }
}

.service-list_item-description {
  &_title {
    font-size: 28px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
  }
  &_content {
    color: #777;
  }
}

.service-list_item-categories {
  .page-services-list & {
    padding-top: 60px;
    @include media-breakpoint-down(xs) {
      padding-top: 30px;
    }
  }
  li {
    line-height: 2.1;
    &:before {
      @include font-awesome;
      content: '\f0ad';
      font-size: 12px;
      display: inline-block;
      margin-right: 10px;
      color: $color-1;
    }
  }
}

/* --- Service-detail --- */
//Layout 01
.service-detail_img img {
  margin-bottom: 35px;
}

.service-detail_content {
  margin-bottom: 50px;
  .heading-title {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  p {
    color: #777;
    line-height: 1.7;
  }
  .service-categories_title {
    font-size: 18px;
    margin: 25px 0 15px;
  }
  .service-list_item-categories {
    margin-bottom: 35px;
  }
}

#accordion-service {
  margin-bottom: 35px;
  .panel-title {
    position: relative;
    a {
      padding-left: 40px;
      &:before {
        @include font-awesome;
        content: "\f0ad";
        font-size: 12px;
        position: absolute;
        top: 15px;
        left: 20px;
        z-index: 9999;
        color: #111;
      }
    }
    a.collapsed:before {
      color: $color-1;
    }
  }
  .panel-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
#collapse-service-one .panel-body {
  padding: 0;
}
.table-service {
  width: 100%;
  th,td {
    padding: 13px 40px;
    width: 50%;
  }
  th {
    background: #eee;
    color: #111;
    font-weight: 700;
    font-size: 17px;
  }
  td {
    color: #777;
  }
  tr {
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: none;
    }
  }
}

//Layout 02
.page-service-detail-02 {
  .service-detail_content {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    p {
      margin-bottom: 15px;
    }
  }
  .service-list_item-categories {
    @extend %clearfix;
    li {
      width: 50%;
      float: left;
    }
    a {
      color: #777;
    }
  }
}
.service-detail_heading {
  .heading-title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  p {
    color: #777;
    margin-bottom: 35px;
  }
}

.service-price {
  text-align: center;
  margin-bottom: 40px;
  &_content {
    border: 2px solid #eee;
    border-top: none;
    border-bottom: none;
  }
  &_heading {
    color: #fff;
    background: $color-1;
    font-size: 18px;
    padding: 20px 0px;
    margin-bottom: 0;
  }
  &_price {
    font-size: 48px;
    color: #111;
    font-weight: 600;
    background: #f7f7f7;
    padding: 30px 0px;
    strong {
      vertical-align: top;
      font-size: 28px;
      margin-left: 5px;
    }
  }
  &_text {
    padding: 0px 20px;
    color: #777;
    li {
      padding: 25px 0;
      border-bottom: 1px solid #eee;
    }
  }
  &_btn {
    padding: 20px 0px;
    margin-bottom: 30px;
    color: #111;
    background: $color-1;
    display: block;
    font-size: 18px;
    font-weight: 600;
    font-family: Montserrat;
    &:hover {
      color: #fff;
      background: #111;
    }
    i {
      margin-left: 5px;
    }
  }
}

//Layout 03
.page-service-detail-03 .table-service {
  border: 1px solid #eee;
  margin-bottom: 30px;
  th {
    background: $color-1;
  }
}
