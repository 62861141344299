.mini-cart {
	padding  : 0 15px;
  display: inline-block;
  margin-bottom: 20px;
	.mini-cart__button {
		cursor : pointer;
		@extend %transit;
		.mini-cart-icon {
			position : relative;
			&:before {
				@extend %icon;
				float       : none;
				margin      : 0;
				width       : auto;
				height      : auto;
				border      : none;
				content     : '\f07a';
				font-size   : 20px;
				line-height : normal;
			}
			&:after {
				position    : absolute;
				top         : -16px;
				right       : -12px;
				min-width   : 20px;
				content     : attr(data-count);
				text-align  : center;
				font-weight : 400;
				font-size   : 12px;
				font-family : inherit;
				line-height : 20px;
        color: #fff;
        background: #2e2e2e;
        @include border-radius(100%);
			}
		}
	}
	.mini-cart__content {
		position: absolute;
		top: 140%;
		left: 50%;
    margin-left: -150px;
		z-index: 99999;
		visibility: hidden;
		overflow: auto;
    text-align: left;
		padding-top: 15px;
    border-top: 3px solid $color-1;
		max-height: 700px;
		width: 300px;
		background-color: #fff;
		opacity: 0;
		@include box_shadow(0 6px 12px 0 rgba(0, 0, 0, 0.2));
		@extend %transit;
		.cart-list {
			li {
				margin-bottom : 10px;
				padding       : 0 15px 10px;
				border-bottom : 1px solid #eee;
        list-style: none;
				@extend %clearfix;
				img {
					float  : left;
					margin : 0 10px 0 0;
					width  : 70px;
				}
        a {
          color: #111;
          font-weight: 700;
        }
				.quantity {
					display   : block;
					color     : #666;
					font-size : 14px;
				}
				.remove {
					position    : relative;
					display     : inline-block;
					float       : right;
					width       : 20px;
					height      : 20px;
					text-align  : center;
					font-size   : 18px;
					line-height : 17px;
					&:hover {
						background : $color-2;
						color      : #fff;
					}
				}
			}
		}
		.total {
			margin-bottom : 0;
			padding       : 5px 15px 5px;
			text-align    : center;
      color: #111;
		}
		.buttons {
			margin-bottom : 0;
			text-align    : center;
		}
		.btn {
			margin : 10px 3px 15px;
		}
    &-open {
      top        : 100%;
      visibility : visible;
      opacity    : 1;
    }
    .amount {
      color: $color-2;
    }
	}
}

///////////////sm//////////////////
@include media-breakpoint-up(sm) {
  .mini-cart {
    .mini-cart__content {
      color: #666;
      right: 15px;
      left: auto;
      margin-left: 0;
      text-align: left;
      top: 160%;
      &-open {
        top: 140%;
      }
    }
  }
}
