// Bootstrap Grid only
// Includes relevant variables and mixins for the regular (non-flexbox) grid
// system, as well as the generated predefined classes (e.g., `.col-4-sm`).
$enable-flex          : true;
$enable-shadows       : true;
$enable-gradients     : true;
$enable-transitions   : true;
$font-size-root       : 10px;
$link-hover-decoration: none;

// Grid system
// Define your custom responsive grid.
$grid-breakpoints : (
	// Extra small screen / phone / 320px
	xs: 20em,
	// Small screen / phone / 768px
	sm: 48em,
	// Medium screen / tablet / 1024px
	md: 64em,
	// Large screen / desktop / 1280px
	lg: 80em,
	// Extra large screen / wide desktop / 1920px
	xl: 120em
);

// Number of columns in the grid.
$grid-columns : 12;

// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width : 3rem;

// Container sizes
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths : (
	sm: 64rem, // 640
	md: 96rem, // 960
	lg: 117rem, // 1170
);

//Color
$color-1  : #FBD232;
$color-2  : #d90000;
$primary-color: #FBD232;
