/* ----------- PIECE OF US ----------- */
.piece-of-us {
  text-align: center;
  padding: 60px 0px;
  background: #eee;
  .page-projects-single & {
    margin-top: 70px;
  }
  &_icon {
    font-size: 26px;
    color: #111;
    position: relative;
    width: 40px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    background: $color-1;
    &::before, &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border: solid rgba(0,0,0,0);
    }
    &::before {
      border-width: 30px 15px 30px 0;
      border-right-color: $color-1;
      right: 100%;
    }
    &::after {
      border-width: 30px 0px 30px 15px;
      border-left-color: $color-1;
      left: 100%;
    }
  }
  h2 {
    color: #1d2736;
    font-size: 32px;
    text-transform: capitalize;
    line-height: 1;
    margin: 15px 0px
  }
  p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 15px;
  }
}

.piece-of-us.piece-of-us_2 {
  background: #111;
  h2 {
    color: #fff;
  }
  p {
    color: #777;
  }
}

@include media-breakpoint-up(md) {
  .piece-of-us {
    position: relative;
    overflow: hidden;
    padding: 0;
    h2, p {
      text-align: left;
      margin-bottom: 5px;
    }
    [class*="col"] {
      padding: 55px 0px;
      * {
        position: relative;
        z-index: 6;
      }
    }
    .col-md-8, .col-md-10 {
      &:before {
        content: '';
        display: block;
        width: calc(100% - 90px);
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 3;
        background: #eee;
      }
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 180px 0px 180px 100px;
        border-color: #fff;
        border-left-color: #eee;
        right: -10px;
        z-index: 4;
        @include translate-y-center;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 900px;
      height: 100%;
      background: #fff;
      z-index: 0;

    }
  }

  .piece-of-us.piece-of-us_2 {
    &:after {
      content: url(../images/page-home/home02_section6_bg.jpg);
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
    }
    .col-md-10:before {
      background: #111;
      z-index: 4;
    }
    .col-md-10:after {
      border-color: rgba(0,0,0,0);
      border-left-color: #111;
      z-index: 5;
    }
  }
}

/* ----------- PIECE OF US 2 ----------- */
.piece-of-us_2 {

}

/* ----------- POST SHARE BUTTONS ------------- */
.post-share-buttons {
  background: #f7f7f7;
  display: inline-block;
  margin: 0px 0px 30px;
  span {
    position: relative;
    font-size: 13px;
    color: #111;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px;
    margin-right: 30px;
    background: $color-1;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 0 25px 15px;
      border-color: rgba(0, 0, 0, 0);
      position: absolute;
      right: -15px;
      top: 0;
      border-left-color: $color-1;
    }
  }
  i {
    font-size: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}
