/* ---------- Projects Single ---------- */
.page-projects-single {
  .single-image {
    margin-bottom: 20px;
  }
}
.project-title {
  font-size: 32px;
  line-height: 38px;
  color: #111;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.project-description{
  &_title {
    font-size: 24px;
    line-height: 29px;
    color: #111;
    margin-bottom: 20px;
  }
  &_content {
    color: #777;
    margin-bottom: 35px;
  }
}

.project-details {
  margin-bottom: 40px;
  table {
    border: 2px solid #eee;
    width: 100%;
    td {
      font-size: 15px;
      border-bottom: 2px solid #eee;
      padding: 25px 20px;
    }
    .meta-title {
      color: $color-1;
      font-weight: 700;
      display: block;
    }
    .meta-value {
      color: #777;
      a {
        color: #777;
      }
    }
  }
}
