/* --- Pagination --- */
.pagination {
  text-align: center;
  display: block;
  margin: 40px 0px 50px;
  ul {
    font-size: 0;
  }
  li {
    display: inline-block;
    a {
      position: relative;
      text-align: center;
      display: block;
      width: 25px;
      height: 38px;
      line-height: 38px;
      font-size: 16px;
      font-weight: 700;
      margin: 0px 15px;
      color: #111;
      background: #eee;
      &:before,&:after {
        content: "";
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: rgba(0,0,0,0);
        @extend %transit;
      }
      &:before {
        left: -10px;
        border-right-color: #eee;
        border-width: 19px 10px 19px 0px;
      }
      &:after {
        right: -10px;
        border-left-color: #eee;
        border-width: 19px 0px 19px 10px;
      }
    }
    &.selected, &:hover {
      a {
        color: #fff;
        background: #111;
        &:before {
          border-right-color: #111;
        }
        &:after {
          border-left-color: #111;
        }
      }
    }
    &.next {
      a {
        background: $color-1;
        &:before {
          border-right-color: $color-1;
        }
        &:after {
          border-left-color: $color-1;
        }
      }
      &:hover {
        a {
          background: #111;
          &:before {
            border-right-color: #111;
          }
          &:after {
            border-left-color: #111;
          }
        }
      }
    }
  }
}
