ul, ol {
    margin : 0;
    padding : 0;
}

ul {
    list-style : none;
}

ol {
    list-style : decimal;
}

li > ul,
li > ol {
    margin-bottom : 0;
    margin-left   : 1.5em;
}

dt {
    font-weight : bold;
}

dd {
    margin : 0 1.5em 1.5em;
}
