.post {
  .entry-meta {
    padding: 6px 0px 6px 15px;
    background: $color-1;
    display: inline-block;
    width: 100%;
    span {
      margin-right: 40px;
      &:last-child {
        margin-right: 20px;
      }
      a {
        color: #111;
      }
    }
  }
  .entry-title {
    a {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 20px;
      color: #111;
      &:hover {
        color: $color-1;
      }
    }
  }
  .entry-content {
    color: #777;
  }
  blockquote {
    border-left-width: 10px;
  }
  .read-more {
    color: $color-1;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
    i {
      margin-left: 5px;
    }
  }
}

.post-list-item,
.post-full-item {
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.post-grid-layout,
.post-list-item,
.post-full-item {
  border-bottom: 1px solid #eee;
}

.post-grid-item,
.post-full-item {
  padding-bottom: 20px;
  .entry-meta {
    margin-bottom: 20px;
  }
  .entry-title {
    margin-bottom: 20px;
    a {
      font-size: 24px;
    }
  }
}

.post-grid-item,
.post-simple-item {
  margin-bottom: 30px;
  .entry-title{
    line-height: 1;
    a {
      font-size: 15px;
    }
  }
}

.post-list-item {
  .entry-meta {
    margin-bottom: 5px;
  }
  .entry-title {
    margin-bottom: 10px;
  }
}

@include media-breakpoint-up(md) {
  .post {
    .entry-meta {
      width: auto;
    }
    .entry-meta:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-color: rgba(0,0,0,0);
      border-width: 17px 0px 17px 10px;
      position: absolute;
      left: 100%;
      top: 0;
      border-left-color: $color-1;
    }
    blockquote {
      margin: 24px 40px;
    }
  }
  .post-list-item {
    .entry-meta {
      position: relative;
    }
  }
  .post-grid-item,
  .post-simple-item,
  .post-full-item {
    padding-bottom: 20px;
    .post-thumb {
      position: relative;
      margin-bottom: 35px;
      .entry-meta {
        margin-bottom: 0;
        position: absolute;
        left: 0;
        bottom: -17px;
      }
    }
  }
}
