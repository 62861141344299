.owl-dots {
  text-align: center;
  margin-top: 20px;
}
.owl-dot {
  display: inline-block;
  width: 30px;
  height: 5px;
  background: #ddd;
  margin: 2px;
  @extend %transit;
  &.active, &:hover {
    background: $color-1;
  }
}

.testimonial {
  .page-home01 & {
    padding: 60px 0px 70px;
    @include clearfix;
  }
  img {
    width: 70px;
    height: auto;
  }
  .content-element {
    margin-bottom: 40px;
  }
  blockquote {
    border: 2px solid $color-1;
    padding: 30px 40px 36px 90px;
    margin-bottom: 30px;
    position: relative;
    p {
      font-size: 18px;
      font-style: italic;
      line-height: 1.6;
      color: #777;
    }
    &:before {
      font-family: FontAwesome;
      font-size: 40px;
      font-weight: 700;
      font-style: normal;
      line-height: 1;
      color: $color-1;
      display: inline-block;
      content: "\f10d";
      position: absolute;
      top: 30px;
      left: 30px;
    }
    &:after {
      content: '';
      width: 20px;
      height: 2px;
      position: absolute;
      left: 30px;
      bottom: -2px;
      background: #fff;
    }
  }
  .avatar-link {
    float: left;
    margin-right: 20px;
  }
  .author {
    span {
      display: block;
      font-style: normal;
      text-transform: capitalize;
    }
    &-name {
      font-size: 18px;
      font-weight: 700;
      color: #111;
    }
    &-job {
      font-size: 15px;
      color: #999;
    }
  }
}

@include media-breakpoint-up(sm) {
  .testimonial {
    .owl-dots {
      position: absolute;
      right: 0;
      bottom: 30px;
    }
  }
}

//Testimonial
.testimonial-2 {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  .col-md-offset-6 {
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgba(17,17,17,0.95);
    @include media-breakpoint-up(sm) {
      &:before {
        content: '';
        width: 80px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
        background: rgba(17,17,17,0.95);
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-color: rgba(0,0,0,0);
        border-width: 300px 0 300px 150px;
        border-left-color: rgba(17,17,17,0.95);
        position: absolute;
        top: 50%;
        left: 100%;
        @include translate(0,-50%);
      }
    }
  }
  .heading-title {
    color: #fff;
    margin-bottom: 40px;
  }
  blockquote {
    &:after {
      background: #111;
    }
    p {
      color: #ccc;
    }
  }
  .author-name {
    color: #fff;
  }
}
