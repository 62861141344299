.our-works {
  padding-top: 50px;
  p {
    color: #666;
    margin-bottom: 30px;
  }
}

// Button
.filters-button-group {
  .button:hover, .button.is-checked {
    background: #fff;
    &::before {
      border-left-color: $color-1;
    }
    &::after {
      border-left-color: #fff;
    }
  }
  .button {
    position: relative;
    color: #111;
    background: $color-1;
    float: left;
    margin: 0;
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 700;
    padding: 5px 20px 5px 35px;
    line-height: 38px;
    &:focus {
      outline: none;
    }
    &:before, &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0);
      z-index: 1;
      top: 0;
      border-left-color: $color-1;
      @extend %transit;
    }
    &:before {
      border-width: 24px 0 24px 16.5px;
      right: -16px;
      border-left-color: rgba(255,255,255,0.2);
    }
    &:after {
      border-width: 24px 0 24px 15px;
      right: -14px;
    }
  }
}

/* ---- ISOTOPE ---- */

.grid:after {
  content: '';
  display: block;
  clear: both;
}

.element-item {
  position: relative;
  overflow: hidden;
  float: left;
  width: 100%;
  height: auto;
}

@include media-breakpoint-up(sm) {
  .element-item {
    width: calc(100%/2);
  }
}

@include media-breakpoint-up(md) {
  .element-item {
    width: calc(100%/3);
  }
}

@include media-breakpoint-up(lg) {
  .element-item {
    width: calc(100%/5);
  }
}

.grid-overlay {
  position: absolute;
  top: -100%;
  background: rgba(17,17,17,0.90);
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  &-category, &-tittle {
    display: block;
  }
  &-category {
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    color: #bbb;
    &:hover {
      color: #bbb;
    }
  }
  &-tittle {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: $color-1;
    &:hover {
      color: $color-1;
    }
  }
  &-icon {
    display: inline-block;
    color: #111;
    font-size: 17px;
    line-height: 44px;
    height: 44px;
    width: 30px;
    margin-top: 20px;
    background: $color-1;
    position: relative;
    &:hover {
      color: #111;
    }
    &:before, &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0);
      position: absolute;
      top: 0;
    }
    &:before {
      border-width: 22px 12.5px 22px 0;
      left: -12px;
      border-right-color: $color-1;
    }
    &:after {
      border-width: 22px 0px 22px 12.5px;
      right: -12px;
      border-left-color: $color-1;
    }
  }
}
